<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>About Us</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  
                  <p>Druk Gyal Sumpa His Late Majesty King Jigme Dorji Wangchuck founded Yangchenphug in 1965. Initially, Yangchenphug was a co-educational residential school, Thimphu Public School, run on the pattern of Public Schools in neighboring India. The school had classes from kindergarten to four, with an enrolment of 91 students and 6 teachers. <br>
In 1976, Thimphu Public School was renamed Yangchenphug Central School. <br>
In 1986 Yangchenphug Central School was named as Yangchenphug High School.<br>
In 2001, Yangchenphug High School was renamed as Yangchenphug Higher Secondary School.<br>
YHSS was initially affiliated to the Indian Council for Secondary Education, India, and Bhutan Board of Examinations, Thimphu, for the Grade 10 and 12 Examinations respectively. In 1975, the class X Indian Certificate of Secondary Educations commenced.
<br><br>
<strong>
School background statistics</strong>
<br>
Established : April 1965<br>
Name : Thimphu Public School<br>
First Headmaster : Mr. Oommen (1965 – 66)<br><br>

First set of teachers<br>

Lop Dago Tshering-Dzongkha<br>
Mrs. Gagay Lham - English<br>
Mrs. Dorothy Sitling - History<br>
Mr. P. Girijan - Math &amp; Science<br>
Mr. Ramachandran - Geography<br>
Mr. Kaka Dorji - Hostel Superintendent<br><br>
Second Headmaster – Mr. Kichulo – (1967 – 1968)<br>
Third Headmaster – Mr. Ramachandran – (1968 – 1969)<br>
Fourth Headmaster – Maj. Sen – (1969) </p>
   
   
              </div>
              
          <div class="sidebar">
              <AboutUsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AboutUsQuickLinks from '../../components/page/AboutUsQuickLinks.vue';
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AboutUsQuickLinks, Footer },
    setup(){
        const title= ref('Background')
        return {title}
    }
    
  
}
</script>

<style scoped>

</style>